<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">本账号客户汇总</span>
      <download-excel
        class="export-excel-wrapper"
        v-if="tabledata && tabledata.length != 0"
        :data="tabledata"
        :fields="json_fields"
        name="本账号客户汇总.xls"
      >
        <el-button type="text" v-if="tabledata && tabledata.length != 0"
          >导出excel表格</el-button
        >
      </download-excel>
    </div>
    <div class="content">
      <div class="sum_number">
        <el-descriptions border class="summary" size="small" :column="3">
          <el-descriptions-item label="客户总数" width="16.7%">{{
            totalCustomNumeber
          }}</el-descriptions-item>
          <el-descriptions-item label="直播快当前资源总数" width="16.7%">{{
            totalPathNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="云手机当前资源总数" width="16.7%">{{
            totalPhoneNumber
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="search-line">
        <el-input
          class="search-input"
          v-model="searchQuery"
          size="mini"
          placeholder="请输入搜索文本"
          style="width: 250px"
          @keyup.enter.native="handleSearch"
          clearable
        />
        <el-button
          class="filter-item"
          size="mini"
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
        <div class="search-tips">
          支持公司名/手机号模糊搜索，无觅ID/关联ID精确搜索
        </div>
      </div>
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="channelsubaccount"
        :pagination="pagination"
        tableheight="calc(100vh - 350px)"
        :data="tabledata"
      >
      </com-table>
    </div>
    <edit-channel-outer-id
      title="关联ID"
      :row="row"
      :showeditchannelouterid="showeditchannelouterid"
      @closeeditchannelouterid="closeeditchannelouterid"
    />
    <edit-company
      title="公司名称"
      :row="row"
      :showeditcompany="showeditcompany"
      @closeeditcompany="closeeditcompany"
    />
  </div>
</template>
<script>
import formatdate from "@/utils/format";
import ComTable from "@/components/Table";
import { channelsubaccount } from "@/utils/tableList";
import EditCompany from "./Models/EditCompany";
import EditChannelOuterId from "./Models/EditChannelOuterId.vue";

export default {
  name: "channelsubaccount",
  data() {
    return {
      channelsubaccount,
      searchQuery: "",
      json_fields: {
        公司名称: {
          callback: (value) => {
            return value.CompanyName || "-";
          },
        },
        无觅手机号: "PhoneNumber",
        注册日期: {
          callback: (value) => {
            return formatdate(value.CreateTime, "YYYY-MM-DD");
          },
        },
        无觅ID: "UserId",
        审核状态: "AuditStatus",
        直播快折扣: {
          callback: (value) => {
            return value.PathDiscount || "1.0";
          },
        },
        直播快当前资源数: {
          callback: (value) => {
            return value.PathCurrentCount || "0";
          },
        },
        云手机折扣: {
          callback: (value) => {
            return value.PhoneDiscount || "1.0";
          },
        },
        云手机当前资源数: {
          callback: (value) => {
            return value.PhoneCurrentCount || "0";
          },
        },
        路由器当前资源数: {
          callback: (value) => {
            return value.RouterCount || "0";
          },
        },
        关联ID: {
          callback: (value) => {
            return value.ChannelOuterId || "-";
          },
        },
        邀请人: {
          callback: (value) => {
            return value.Inviter || "-";
          },
        },
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      tabledataAll: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      title: "",
      tempdata: [],
      showeditchannelouterid: false,
      showeditcompany: false,
      totalCustomNumeber: 0,
      totalPathNumber: 0,
      totalPhoneNumber: 0,
    };
  },
  components: {
    ComTable,
    EditChannelOuterId,
    EditCompany,
  },
  mounted() {
    this.getgrouplist();
    window.editChannelOuterId = this.editChannelOuterId;
    window.editChannelCompany = this.editChannelCompany;

    this.getuserinfo();
  },
  methods: {
    getgrouplist() {
      var tempdata = [];
      this.$store.dispatch("user/channelsublist").then((res) => {
        if (res.RetCode == 0) {
          this.tabledata = res.List || [];
          this.tabledataAll = res.List || [];
          this.totalCustomNumeber = this.tabledata.length;
          for (let i = 0; i < this.tabledata.length; i++) {
            this.totalPathNumber =
              this.totalPathNumber + this.tabledata[i].PathCurrentCount;
            this.totalPhoneNumber =
              this.totalPhoneNumber + this.tabledata[i].PhoneCurrentCount;
          }
        }
      });
    },
    handleSearch() {
      if (this.searchQuery == "") {
        this.tabledata = this.tabledataAll;
      } else {
        let tmp = [];
        for (const i of this.tabledataAll) {
          if (i.PhoneNumber.indexOf(this.searchQuery) != -1) {
            tmp.push(i);
          } else if (i.CompanyName.indexOf(this.searchQuery) != -1) {
            tmp.push(i);
          } else if (i.UserId == this.searchQuery) {
            tmp.push(i);
          } else if (i.ChannelOuterId == this.searchQuery) {
            tmp.push(i);
          }
        }
        this.tabledata = tmp;
      }
    },
    editChannelOuterId(row) {
      this.row = row;
      this.showeditchannelouterid = !this.showeditchannelouterid;
    },
    closeeditchannelouterid() {
      this.showeditchannelouterid = !this.showeditchannelouterid;
      this.getgrouplist();
    },
    editChannelCompany(row) {
      this.row = row;
      this.showeditcompany = !this.showeditcompany;
    },
    closeeditcompany(row) {
      this.showeditcompany = !this.showeditcompany;
      this.getgrouplist();
    },

    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>