<template>
  <el-dialog
    :visible.sync="showmodel"
    @open="open"
    @opened="opened"
    :before-close="closemodel"
    :close-on-click-modal="closeonclickmodal"
  >
    <div slot="title" class="dialog-title">
      <span>{{ title }}</span>
    </div>
    <slot></slot>
    <div v-if="needfooter" slot="footer" class="dialog-footer">
      <el-button
        v-if="needcancel"
        size="mini"
        class="cancelbtn"
        @click="closemodel"
        type="primary"
        >{{ canceltext }}</el-button
      >
      <el-button
        @click="confirm"
        size="mini"
        class="confirmbtn"
        type="primary"
        :disabled="isConfirm"
        >{{ confirmtext }}</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showmodel: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确认",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    needfooter: {
      type: Boolean,
      default: true,
    },
    closeonclickmodal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isConfirm: false,
    };
  },
  methods: {
    closemodel() {
      (this.isConfirm = false), this.$emit("closemodel");
    },
    confirm() {
      (this.isConfirm = true), this.$emit("confirm");
    },
    open() {
      (this.isConfirm = false), this.$emit("open");
    },
    opened() {
      this.$emit("opened");
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-title {
  height: 53px;
  border-bottom: 1px solid rgb(195, 202, 217);
  box-sizing: border-box;
  padding: 16px;
}
.dialog-footer {
  height: 53px;
  padding: 15px 16px;
  box-sizing: border-box;
  border-top: 1px solid rgb(195, 202, 217);
  .confirmbtn {
    border-radius: 5px;
    border: 1px solid #409eff;
    background: #409eff;
    &:hover {
      background: #409eff;
    }
  }
  .cancelbtn {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #409eff;
    color: rgba(102, 102, 102);
    &:hover {
      color: #409eff;
    }
  }
}
</style>